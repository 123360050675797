import customAxiosDroDown from "../../serverCalldrodpwn";
import customAxiosDroDownFootball from "../../serverCalldrodpwnfootball";

const Series = async (status) => {
    // let response = ""
    const response = await customAxiosDroDown.get(
        `/ce/series`
    );
    // response = await customAxiosDroDownFootball.get(
    //     `/score/onboarding`
    // );
    // }
    return response;
};
const SeriesFootball = async (status) => {
    // let response = ""
    // const response = await customAxiosDroDown.get(
    //     `/ce/series`
    // );
    const response = await customAxiosDroDownFootball.get(
        `/score/onboarding`
    );
    // }
    return response;
};
const Teams = async (status) => {
    const response = await customAxiosDroDown.get(
        `/ce/teams`
    );
    return response;
};
const PLayers = async (status) => {
    const response = await customAxiosDroDown.get(
        `/ce/players`
    );
    return response;
};


const DropdownService = {
    Teams,
    PLayers,
    Series,
    SeriesFootball
};

export default DropdownService;
