import { get } from "lodash";
import serverCall from "../../serverCall";

const signUp = (userSignInRequest) => {
  return serverCall
    .post(`/auth/login`, userSignInRequest)
    .then((response) => {
      const data = get(response, "data", null);
      if (data) {
        const user = data.data;
        const token = data.token;
        // localStorage.setItem("token", token);
        const userAndToken = {
          user,
          token,
        };
        localStorage.setItem("userProfile", JSON.stringify(userAndToken));
      }
      return data;
    });
};

const changePassWord = (userSignInRequest) => {
  return serverCall
    .post(`/auth/chnage-password`, userSignInRequest)
};

const AuthenticationService = {
  signUp,
  changePassWord
};

export default AuthenticationService;
