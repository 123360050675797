import serverCall from "../../serverCall";

const Notification = async (data, type) => {
    const response = await serverCall.post(
        `/notification/send/${type}`, data
    );
    return response;
};
const NotificationUpdate = async (id, data, type) => {
    const response = await serverCall.patch(
        `/notification/edit/${type}/${id}`, data
    );
    return response;
};
const getAllNotification = async (page, pageSize, type) => {
    const response = await serverCall.get(
        `/notification/all/${type}?pageNumber=${page - 1}&pageSize=${pageSize}`
    );
    return response;
};
const getNotification = async (type, id) => {
    const typeCap = type.toUpperCase()
    const response = await serverCall.get(
        `/news/${id}?apptype=${typeCap}`
    );
    return response;
};
const getSingleNotification = async (id, type) => {
    const response = await serverCall.get(
        `/notification/get/${type}/${id}`
    );
    return response;
};

const deleteNotification = async (id, type) => {
    const response = await serverCall.delete(
        `/notification/delete/${type}?id=${id}`
    );
    return response;
};

const NotificationService = {
    Notification,
    getAllNotification,
    deleteNotification,
    getNotification,
    getSingleNotification,
    NotificationUpdate
};

export default NotificationService;
