import serverCall from "../../serverCall";

const getAllStory = async (pageNumber, pageSize) => {
  const response = await serverCall.get(
    `/story?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response;
};
const uploadFile = async (files) => {
  const response = await serverCall.post(`/story/uploadFile`, files);
  return response;
};
const createStory = async (body) => {
  const response = await serverCall.post(`/story/create-story`, body);
  return response;
};
const deleteStory = async (id) => {
  const response = await serverCall.delete(`/story/${id}`);
  return response;
};
const getStoryDetails = async (id) => {
  const response = await serverCall.get(`/story/${id}`);
  return response;
};

const updateStory = async (body) => {
  const response = await serverCall.put(`/story/update-story`, body);
  return response;
};

const getFootballAllStory = async (pageNumber, pageSize) => {
  const response = await serverCall.get(
    `/football-story?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response;
};
const uploadFootballFile = async (files) => {
  const response = await serverCall.post(`/football-story/uploadFile`, files);
  return response;
};
const createFootballStory = async (body) => {
  const response = await serverCall.post(`/football-story/create-story`, body);
  return response;
};
const deleteFootballStory = async (id) => {
  const response = await serverCall.delete(`/football-story/${id}`);
  return response;
};
const getFootballStoryDetails = async (id) => {
  const response = await serverCall.get(`/football-story/${id}`);
  return response;
};

const updateFootballStory = async (body) => {
  const response = await serverCall.put(`/football-story/update-story`, body);
  return response;
};

const StoryService = {
  getAllStory,
  uploadFile,
  createStory,
  deleteStory,
  getStoryDetails,
  updateStory,
  getFootballAllStory,
  uploadFootballFile,
  createFootballStory,
  deleteFootballStory,
  getFootballStoryDetails,
  updateFootballStory,
};

export default StoryService;
