import serverCall from "../../serverCall";

const getAllNews = async (data) => {
  const response = await serverCall.post("news/all/get/admin",data);
  return response;
};

const GetSingleUserData = async (id) => {
  const response = await serverCall.get(
    `/user/find-one-detail/admin?userId=${id}`
  );
  return response;
};
const UserDelete = async (id) => {
  const response = await serverCall.delete(
    `/user/delete-account/admin?userId=${id}`
  );
  return response;
};
const UpdateNews = async (id, data, type) => {
  const response = await serverCall.patch(`/news/${type}/${id}`, data);
  return response;
};
const AddNews = async (type, data) => {
  const appType = type.toLowerCase()
  const response = await serverCall.post(`/news/create/${appType}`, data);
  return response;
};
const GetNews = async (id) => {
  const response = await serverCall.get(
    `/news/${id}`
  );
  return response;
};
const DeleteNews = async (id, apptype) => {
  const response = await serverCall.delete(
    `/news/${id}/${apptype}`
  );
  return response;
};

const NewsService = {
  getAllNews,
  GetSingleUserData,
  UserDelete,
  GetNews,
  UpdateNews,
  AddNews,
  DeleteNews
};

export default NewsService;
