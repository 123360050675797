import React, { useEffect, useState } from "react";
import { StoryTable } from "../allTables/allTable";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import StoryService from "../../services/story.service";

const FootballStoryList = () => {
  const navigate = useNavigate();
  const newPage = localStorage.getItem("footballStoryPage");
  const newData = localStorage.getItem("footballStoryData");
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(newPage !== null ? newPage : 1);
  const [dataPerPage, setDataPerPage] = useState(
    newData !== null ? newData : 10
  );
  const [totalRows, setTotalRows] = useState(0);

  const getAllStory = async () => {
    const response = await StoryService.getFootballAllStory(
      pageNumber,
      dataPerPage
    );
    setData(response.data.data);
    setTotalRows(response.data.totalRecord);
  };

  useEffect(() => {
    getAllStory();
    localStorage.setItem("cricketStoryPage", pageNumber);
    localStorage.setItem("cricketStoryData", dataPerPage);
  }, [pageNumber, dataPerPage]);

  const handleOpen = async () => {
    navigate("/football/story/add");
  };

  const handlePerPageChange = (newPerPage, newPageNumber) => {
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  return (
    <div>
      <div className="page-header2"></div>
      <div className="row">
        <Col
          sm={12}
          md={6}
          lg={12}
          xl={12}
          className="ms-auto mt-auto d-flex"
          style={{ justifyContent: "end", alignItems: "baseline" }}
        >
          <div
            className="me-auto d-flex justify-content-start"
            style={{ fontWeight: 600, fontSize: "20px", marginBottom: "10px" }}
          >
            CRICKET STORY
          </div>
          <div
            className="custom-card text-end  pointer"
            style={{ marginRight: "18px", marginTop: "4px" }}
          >
            <Button
              className="text-white"
              onClick={() => handleOpen("")}
              style={{ marginLeft: "15px", marginTop: "-3px" }}
            >
              <i className="fas fa-plus"></i> &nbsp; Add Story
            </Button>
          </div>
        </Col>
      </div>
      <StoryTable
        data={data}
        totalRows={totalRows}
        pageNumber={pageNumber}
        dataPerPage={dataPerPage}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        getAllStory={getAllStory}
        type="football"
      />
    </div>
  );
};

export default FootballStoryList;
