import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChangePass } from "../../authentication/changePass";

function Header() {
  const user = JSON.parse(localStorage.getItem("userProfile"));
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const name = user?.user?.email;

  useEffect(() => {
    document.addEventListener("click", handlePageClick);

    return () => {
      document.removeEventListener("click", handlePageClick);
    };
  }, []);

  const handlePageClick = (event) => {
    const target = event.target;
    const sidebarToggle = document.getElementById("mainSidebarToggle");

    if (
      target !== sidebarToggle &&
      target.closest(".main-header-left") === null
    ) {
      closeSidebar();
    }
  };

  const handleChangePass = () => {
    setModalShow(true);
  };

  const closeSidebar = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.remove("main-navbar-show");
      } else {
        body.classList.remove("main-sidebar-show");
      }
    }
  };

  const handleLogin = () => {
    localStorage.clear();
    window.location.reload();
    closeSidebar();
  };

  const headerToggleButton = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.toggle("main-sidebar-hide");
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.toggle("main-navbar-show");
        body.classList.remove("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      } else {
        body.classList.toggle("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      }
    }
  };

  return (
    <Fragment>
      <Navbar expand="lg" className="main-header side-header sticky">
        <Container fluid className="main-container container-fluid">
          <div className="main-header-left">
            <Link
              to="#"
              className="main-header-menu-icon"
              id="mainSidebarToggle"
              onClick={headerToggleButton}
            >
              <span></span>
            </Link>
          </div>

          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>
            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  {}
                  <Dropdown className="main-profile-menu">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{name}</div>
                        <span className="main-img-user mx-1">
                          <img
                            alt="avatar"
                            src={require("../../../assets/img/users/male.png")}
                          />
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        margin: "0px 5px",
                        padding: "0px",
                        minWidth: "50px",
                      }}
                    >
                      <Dropdown.Item
                        onClick={handleChangePass}
                        style={{ cursor: "pointer", height: "45px" }}
                      >
                        <p className="main-notification-text">
                          <i className="fe fe-refresh-cw ms-1"></i> Change
                          Passsword
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleLogin}
                        style={{ cursor: "pointer", height: "45px" }}
                      >
                        <p className="main-notification-text">
                          <i className="fe fe-power ms-1"></i> Sign Out
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
      {modalShow && <ChangePass show={modalShow} onHide={handleClose} />}
    </Fragment>
  );
}

export default Header;
