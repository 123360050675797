import axios from "axios";
import config from "./config";
import EncryptDecryptService from "./app/services/encryptdecrypt.service";

const BASE_URL = config.baseApiDropDown;
const appName = process.env.REACT_APP_NAME;
const packageName = process.env.REACT_APP_PACKEGENAME;

const customAxiosDroDown = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  const newDate = new Date().getTime();

  if (request.data !== undefined && request.data !== null) {
    const encryptedData = EncryptDecryptService.encrypt(request.data);
    request.data = encryptedData;
  }
  const combinedString = `${newDate}##${packageName}`;
  const signature = EncryptDecryptService.encrypt(combinedString);

  request.headers["signature"] = signature;
  request.headers["ts"] = newDate;
  request.headers["X-Client-Identifier"] = appName;

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 403) {

    window.location = "/";
    localStorage.removeItem("token");
  }
  if (response.data) {
    const decryptedData = EncryptDecryptService.decrypt(response.data);
    response.data = decryptedData;
  }
  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = "/";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxiosDroDown.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxiosDroDown.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxiosDroDown;
