import football from "../../../assets/img/svgs/football.svg";
import cricket from "../../../assets/img/svgs/cricket.svg";

export const MENUITEMS = [
  {
    Items: [
      {
        path: `/cricket`,
        icon: cricket,
        type: "sub",
        active: false,
        selected: false,
        title: "Cricket",
        children: [
          {
            path: `/cricket/news`,
            type: "link",
            active: true,
            selected: true,
            title: "News",
          },
          {
            path: `/cricket/notification`,
            type: "link",
            active: true,
            selected: true,
            title: "Notification",
          },
          {
            path: `/cricket/story`,
            type: "link",
            active: true,
            selected: true,
            title: "Story",
          },
        ],
      },
      {
        path: `/football`,
        icon: football,
        type: "sub",
        active: false,
        selected: false,
        title: "Football",
        children: [
          {
            path: `/football/news`,
            type: "link",
            active: true,
            selected: true,
            title: "News",
          },
          {
            path: `/football/notification`,
            type: "link",
            active: true,
            selected: true,
            title: "Notification",
          },
          {
            path: `/football/story`,
            type: "link",
            active: true,
            selected: true,
            title: "Story",
          },
        ],
      },
    ],
  },
];
