import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import StoryService from "../../services/story.service";
import { useFormik } from "formik";
import { Divider } from "antd";
import * as yup from "yup";

const FootballStory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addStoryCount, setAddStoryCount] = useState(1);

  const [mainSelectedImage, setMainSelectedImage] = useState("");
  const [subText, setSubText] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [data, setData] = useState([]);
  const [isDeleteClick, setIsDeleteClick] = useState(false);

  const getStoryDetails = async () => {
    if (id !== "add") {
      const response = await StoryService.getFootballStoryDetails(id);
      const result = response.data;
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("image", result.image);
      formik.setFieldValue("credit", result.credit);
      formik.setFieldValue("text", result.text);
      formik.setFieldValue("storyImages", result.storyImages);
      result.storyImages.forEach((story, index) => {
        formik.setFieldValue(`storyImages[${index}].image`, story.image);
        formik.setFieldValue(`storyImages[${index}].text`, story.text);
        formik.setFieldValue(`storyImages[${index}].id`, story.id);
      });
      setData(result.storyImages);
      setAddStoryCount(result.storyImages.length);
    }
  };

  useEffect(() => {
    getStoryDetails();
  }, [id]);

  const schema = yup.object().shape({
    image: yup.mixed().required("Please Select Image"),
    text: yup.string().required("Please enter Text"),
  });

  const formik = useFormik({
    initialValues: {
      image: "",
      text: "",
      credit: "",
      storyImages: [
        {
          id: 0,
          image: "",
          text: "",
          level: 0,
        },
      ],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      values.storyImages.forEach((storyImage, index) => {
        storyImage.level = index;
      });

      if (id === "add") {
        const response = await StoryService.createFootballStory(values);
        // console.log("createStory", response);
      } else {
        const response = await StoryService.updateFootballStory(values);
        // console.log("updateStory", response);
      }
      navigate(-1);
    },
  });

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    const response = await StoryService.uploadFootballFile(formData);
    return response.data;
  };

  const handleMainImage = async (e) => {
    const selectedFile = e.currentTarget.files[0];

    const imageURL = await uploadFile(selectedFile);
    setMainSelectedImage(imageURL);
    formik.setFieldValue(`image`, imageURL);
  };

  const handleSubImage = async (e, index) => {
    const selectedFile = e.currentTarget.files[0];

    const imageUrl = await uploadFile(selectedFile);
    setSelectedImage((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = imageUrl;
      return updatedImages;
    });
    formik.setFieldValue(`storyImages[${index}].image`, imageUrl);
    formik.setFieldValue(`storyImages[${index}].id`, 0);
  };

  const deleteHandler = (index) => {
    // console.log("idddddddddddd", index);
    formik.setFieldValue(
      "storyImages",
      formik.values.storyImages.filter((_, i) => i !== index)
    );
    setAddStoryCount(addStoryCount - 1);
  };

  const deleteHandlerData = (index) => {
    setIsDeleteClick(true);
    formik.setFieldValue(
      "storyImages",
      formik.values.storyImages.filter((_, i) => i !== index)
    );
    setTimeout(() => {
      setIsDeleteClick(false);
    }, 500);
  };

  useEffect(() => {
    if (id !== "add") {
      setData(formik.values.storyImages);
    }
  }, [isDeleteClick]);

  const handleSubText = (e, index) => {
    const input = e.target.value;
    setSubText((prevText) => {
      const updatedText = [...prevText];
      updatedText[index] = input;
      return updatedText;
    });
    formik.setFieldValue(`storyImages[${index}].text`, input);
  };

  return (
    // <div className="mb-3">
    //   <div className="page-header2"></div>
    //   <div className="card p-4">
    //     <div className="row">
    //       <Col
    //         sm={12}
    //         md={6}
    //         lg={12}
    //         xl={12}
    //         className="ms-auto mt-auto d-flex"
    //         style={{ justifyContent: "end", alignItems: "baseline" }}
    //       >
    //         <div
    //           className="me-auto d-flex justify-content-start"
    //           style={{
    //             fontWeight: 600,
    //             fontSize: "20px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           ADD STORY
    //         </div>
    //       </Col>
    //     </div>
    //     <div className="row">
    //       <h6>Main Story</h6>
    //       <Col md={6}>
    //         <img src="https://cms.letsly.in/wp-content/uploads/2024/04/MS-Dhoni-1-3.jpg" />
    //       </Col>
    //       <Col md={6} className="d-flex align-items-center">
    //         <h3>
    //           MS Dhoni CSK Images & HD Wallpapers for Free Download: Get Dhoni
    //           HD Photos in Chennai Super Kings IPL 2024 Jersey To Share Online
    //         </h3>
    //       </Col>
    //     </div>
    //     <Row className="mt-3">
    //       {data.map((item, index) => {
    //         return (
    //           <>
    //             <Col md={3} className="mb-3">
    //               <img
    //                 src={item.img}
    //                 style={{ objectFit: "cover" }}
    //                 height={200}
    //                 width={500}
    //               />
    //             </Col>
    //             <Col md={3} className="d-flex align-items-center mb-3">
    //               <h5>{item.description}</h5>
    //               <button className="btn btn-danger btn-sm mb-auto">
    //                 <i className="fa fa-trash"></i>
    //               </button>
    //             </Col>
    //           </>
    //         );
    //       })}
    //     </Row>
    //     {/* {addStoryCount} */}
    //     {[...Array(addStoryCount)].map((_, index) => (
    //       <>
    //         <Row className="mt-3">
    //           <Col md={3}>
    //             <Form.Control
    //               type="file"
    //               id={`fileInput-${index}`}
    //               // onChange={(e) => handleChange(e, index)}
    //             />
    //           </Col>{" "}
    //           <Col md={3} className="d-flex">
    //             <Form.Control
    //               placeholder="Sub Text"
    //               type="text"
    //               name={`languages[${index}].buttonText`}
    //               // onChange={formik.handleChange}
    //               // value={formik.values.languages[index].buttonText}
    //             />
    //             {addStoryCount > 1 && (
    //               <button
    //                 className="btn btn-danger btn-sm ms-2"
    //                 onClick={() => setAddStoryCount(addStoryCount - 1)}
    //               >
    //                 <i className="fa fa-trash"></i>
    //               </button>
    //             )}
    //           </Col>
    //         </Row>
    //       </>
    //     ))}
    //     <Row>
    //       <Col md={6} className="d-flex justify-content-end mt-3">
    //         <Button
    //           className="btn btn-sm"
    //           onClick={() => {
    //             setAddStoryCount(addStoryCount + 1);
    //           }}
    //         >
    //           {" "}
    //           + Add
    //         </Button>
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
    <div className="mb-3">
      <div className="page-header2"></div>
      <div className="card p-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <Col
              sm={12}
              md={6}
              lg={12}
              xl={12}
              className="ms-auto mt-auto d-flex"
              style={{ justifyContent: "end", alignItems: "baseline" }}
            >
              <div
                className="me-auto d-flex justify-content-start"
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                ADD STORY
              </div>
            </Col>
          </div>
          <h5
            style={{
              fontSize: "18px",
            }}
          >
            Main Story
          </h5>
          <Row className="mt-3 mb-3">
            {!formik.values.image && (
              <>
                <Col md={6}>
                  <Form.Control
                    type="file"
                    id={`fileInput`}
                    onChange={(e) => handleMainImage(e)}
                  />
                  {formik.errors.image && formik.touched.image ? (
                    <p className="text-start error mt-2">
                      {formik.errors.image}
                    </p>
                  ) : null}
                </Col>
                <Col md={3}>
                  <Form.Control
                    placeholder="Main Text"
                    type="text"
                    name="text"
                    onChange={formik.handleChange}
                    value={formik.values.text}
                  />
                  {formik.errors.text && formik.touched.text ? (
                    <p className="text-start error mt-2">
                      {formik.errors.text}
                    </p>
                  ) : null}
                </Col>

                <Col md={3}>
                  <Form.Control
                    placeholder="Credit"
                    type="text"
                    name="credit"
                    onChange={formik.handleChange}
                    value={formik.values.credit}
                  />
                  {formik.errors.credit && formik.touched.credit ? (
                    <p className="text-start error mt-2">
                      {formik.errors.credit}
                    </p>
                  ) : null}
                </Col>
              </>
            )}
          </Row>

          <div className="row">
            <Col md={6}>
              {formik.values.image !== "" && (
                <img
                  src={formik.values.image}
                  width="100%"
                  height={300}
                  style={{ objectFit: "cover" }}
                />
              )}
            </Col>
            <Col md={6} className="d-flex">
              {formik.values.image && (
                <div style={{ width: "100%" }}>
                  <Form.Control
                    type="file"
                    id={`fileInput`}
                    onChange={(e) => handleMainImage(e)}
                    className="mb-3"
                  />
                  <Form.Control
                    placeholder="Main Text"
                    type="text"
                    name="text"
                    onChange={formik.handleChange}
                    value={formik.values.text}
                  />
                  {formik.errors.text && formik.touched.text ? (
                    <p className="text-start error mt-2">
                      {formik.errors.text}
                    </p>
                  ) : null}

                  <Form.Control
                    placeholder="Credit"
                    type="text"
                    name="credit"
                    onChange={formik.handleChange}
                    value={formik.values.credit}
                    className="mt-3"
                  />
                  {formik.errors.credit && formik.touched.credit ? (
                    <p className="text-start error mt-2">
                      {formik.errors.credit}
                    </p>
                  ) : null}
                </div>
              )}
            </Col>
          </div>
          <Divider />

          <Row className="mt-3">
            {[...Array(addStoryCount)].map((_, index) => {
              const newIndex = data?.length + index;
              return (
                <Col md={6} className="mb-4">
                  <Row>
                    {!formik.values.storyImages[index]?.image && (
                      <div className="mt-3 d-flex">
                        <Col md={6}>
                          <Form.Control
                            type="file"
                            id={`fileInput-${index}`}
                            onChange={(e) => handleSubImage(e, index)}
                            name={`storyImages[${index}].image`}
                          />
                        </Col>

                        <Col md={6} className="d-flex justify-content-end">
                          <Form.Control
                            placeholder="Sub Text"
                            type="text"
                            onChange={(e) => handleSubText(e, index)}
                            // value={subText[index]}
                            name={`storyImages[${index}].text`}
                            value={formik.values.storyImages[index]?.text}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => deleteHandler(index)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </Col>
                      </div>
                    )}
                  </Row>
                  {/* <Col md={6}></Col> */}
                  {formik.values.storyImages[index]?.image && (
                    <Row className="mt-3">
                      <Col md={6}>
                        <img
                          src={formik.values.storyImages[index]?.image}
                          style={{ objectFit: "cover" }}
                          height={200}
                          width={500}
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-flex">
                          <Form.Control
                            type="file"
                            id={`fileInput-${index}`}
                            onChange={(e) => handleSubImage(e, index)}
                            name={`storyImages[${index}].image`}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => deleteHandler(index)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                        <Form.Control
                          placeholder="Sub Text"
                          type="text"
                          onChange={(e) => handleSubText(e, index)}
                          // value={subText[index]}
                          name={`storyImages[${index}].text`}
                          value={formik.values.storyImages[index]?.text}
                          className="mt-3"
                        />
                      </Col>
                      {/* <Col md={6}></Col> */}
                    </Row>
                  )}
                </Col>
              );
            })}
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button
              type="button"
              className="btn btn-sm"
              onClick={() => {
                setAddStoryCount(addStoryCount + 1);
              }}
            >
              {" "}
              + Add Sub Story
            </Button>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FootballStory;
