import React, { useRef } from "react";
import { Button, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { UserTable } from "../allTables/allTable";
import NewsService from "../../services/user.service";
import useMenuState from "../../hooks/useMenuState";
import Multiselect from "react-select";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../../hooks/mediaQueryHook";
import DropdownService from "../../services/dropdown.service";

export function DashboardTable() {
  const newPage = localStorage.getItem("newsPage");
  const newData = localStorage.getItem("newsData");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(
    newData !== null ? newData : 10
  );
  const [pageNumber, setPageNumber] = useState(newPage !== null ? newPage : 1);
  const [totalRows, setTotalRows] = useState("");
  const [search, setSearch] = useState("");
  const [searchPlayer, setSearchPlayer] = useState("");
  const [searchTeam, setSearchTeam] = useState("");
  const [searchSeiesFilter, setSearchSeiesFilter] = useState([]);
  const [searchTeamFilter, setSearchTeamFilter] = useState([]);
  const [searchPlayerFilter, setSearchPlayerFilter] = useState([]);
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [statusChange, setStatusChange] = useState("");
  const location = window.location.pathname;
  const [visibleOptions, setVisibleOptions] = useState(10);
  const [visibleOptionsTeam, setVisibleOptionsTeam] = useState(10);
  const [visibleOptionsPlayer, setVisibleOptionsPlayer] = useState(10);
  const [player, setPlayer] = useState([]);
  const [teams, setTeams] = useState([]);
  const [series, setSeries] = useState([]);
  const [statusCode, setStatusCode] = useState([]);
  const isTabletMore = useMediaQuery("(max-width: 576px)");
  let status = "";

  const mergedFilters = [
    ...searchPlayerFilter,
    ...searchTeamFilter,
    ...searchSeiesFilter,
  ];

  const getAllUserData = async () => {
    if (location.includes("/cricket/news/")) {
      status = "cricket";
      setStatusChange("cricket");
    } else {
      setStatusChange("football");
      status = "football";
    }
    const newPageNumber = localStorage.getItem("newsPage");
    if (newPageNumber === null) {
      localStorage.setItem("newsPage", 1);
    }
    const newPageData = localStorage.getItem("newsData");
    if (newPageData === null) {
      localStorage.setItem("newsData", 10);
    }
    const response = await NewsService.getAllNews({
      searchtag: mergedFilters,
      pageNumber: pageNumber - 1,
      pageSize: dataPerPage,
      apptype: status.toUpperCase(),
    });
    setData(response.data.data);
    setTotalRows(response.data.totalRecord);
  };

  useEffect(() => {
    getAllUserData();
    localStorage.setItem("newsPage", pageNumber);
    localStorage.setItem("newsData", dataPerPage);
  }, [
    searchPlayerFilter,
    searchTeamFilter,
    searchSeiesFilter,
    pageNumber,
    dataPerPage,
    location,
    status,
  ]);

  const handlePerPageChange = (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    setIsLoading(false);
  };

  const handlePageChange = (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    setIsLoading(false);
  };

  const handleOpen = async (id) => {
    if (id) {
      if (location.includes("/cricket/news/")) {
        return `/cricket/news/${id}`;
      } else {
        return `/football/news/${id}`;
      }
    } else {
      if (location.includes("/cricket/news/")) {
        return `/cricket/news/${"add"}`;
      } else {
        return `/football/news/${"add"}`;
      }
    }
  };

  const getTeamName = async () => {
    if (location.includes("/cricket/news/")) {
      const response = await DropdownService.Teams();
      setStatusCode(response.status);
      const teamData = JSON.parse(response.data);
      let playerTeamData = Object.values(teamData).sort((a, b) =>
        a.n.localeCompare(b.n)
      );
      setTeams(playerTeamData);
    } else if (location.includes("/football/news/")) {
      const responseData = await DropdownService.SeriesFootball();
      setStatusCode(responseData.status);
      const resultData = JSON.parse(responseData.data);
      const teams = resultData.suggestedTeams;
      const players = resultData.suggestedPlayers;
      const series = resultData.suggestedLeagues;
      let playerTeamData = teams.sort((a, b) => a.name.localeCompare(b.name));
      let playerSeiesData = series.sort((a, b) => a.name.localeCompare(b.name));
      let playerData = players.sort((a, b) => a.name.localeCompare(b.name));
      setTeams(playerTeamData);
      setPlayer(playerData);
      setSeries(playerSeiesData);
    }
  };
  const getPlayerName = async () => {
    if (location.includes("/cricket/news/")) {
      const response = await DropdownService.PLayers();
      setStatusCode(response.status);
      const playerData = JSON.parse(response.data);
      let playerSeriesData = Object.values(playerData).sort((a, b) =>
        a.n.localeCompare(b.n)
      );
      setPlayer(playerSeriesData);
    }
  };

  const getSeriesName = async () => {
    if (location.includes("/cricket/news/")) {
      const response = await DropdownService.Series();
      setStatusCode(response.status);
      const seriesData = JSON.parse(response.data);
      let playerSeriesData = Object.values(seriesData).sort((a, b) =>
        a.n.localeCompare(b.n)
      );
      setSeries(playerSeriesData);
    }
  };

  useEffect(() => {
    getTeamName();
    getPlayerName();
    getSeriesName();
  }, [location]);

  const mapSeriesItem = (item) => ({
    value: location.includes("/cricket/news/") ? item.f_key : item.id,
    label: location.includes("/cricket/news/") ? item.n : item.name,
  });
  const mapPlayerItem = (item) => ({
    value: location.includes("/cricket/news/") ? item.f_key : item.id,
    label: location.includes("/cricket/news/") ? item.n : item.name,
  });
  const mapTeamItem = (item) => ({
    value: location.includes("/cricket/news/") ? item.f_key : item.id,
    label: location.includes("/cricket/news/") ? item.n : item.name,
  });

  const SeriesOptions = Object.values(series).map(mapSeriesItem);
  const TeamOptions = Object.values(teams).map(mapPlayerItem);
  const PlayersOptions = Object.values(player).map(mapTeamItem);

  const filteredOptions =
    SeriesOptions &&
    SeriesOptions?.filter((option) =>
      option.label?.toLowerCase().includes(search.toLowerCase())
    );

  const teamOptions =
    TeamOptions &&
    TeamOptions?.filter((option) =>
      option.label?.toLowerCase().includes(searchTeam.toLowerCase())
    );
  const playerOptions =
    PlayersOptions &&
    PlayersOptions?.filter((option) =>
      option.label?.toLowerCase().includes(searchPlayer.toLowerCase())
    );

  const loadMore = () => {
    setVisibleOptions((prev) => prev + 10);
  };
  const loadMoreTeam = () => {
    setVisibleOptionsTeam((prev) => prev + 10);
  };
  const loadMorePlayer = () => {
    setVisibleOptionsPlayer((prev) => prev + 10);
  };

  const changeSeries = (data) => {
    setSearchSeiesFilter(data.map((item) => "S#" + item.value));
  };
  const changeTeams = (data) => {
    setSearchTeamFilter(data.map((item) => "T#" + item.value));
  };
  const changePLayers = (data) => {
    setSearchPlayerFilter(data.map((item) => "P#" + item.value));
  };

  return (
    <>
      <div className="page-header2"></div>
      <div className="row">
        <Col
          sm={12}
          md={6}
          lg={12}
          xl={12}
          className="ms-auto mt-auto d-flex"
          style={{
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div
            className="me-auto d-flex justify-content-start"
            style={{ fontWeight: 600, fontSize: "20px" }}
          >
            {statusChange === "cricket" ? "CRICKET NEWS" : "FOOTBALL NEWS"}
          </div>

          <div className={`${!isTabletMore && "d-flex"}`}>
            <div
              style={{
                marginRight: "15px",
                maxWidth: "230px",
                minWidth: "230px",
              }}
            >
              <Multiselect
                classNamePrefix="Select2 z-index-10"
                options={filteredOptions.slice(0, visibleOptions)}
                name="series"
                placeholder="Series"
                onChange={(e, data) => changeSeries(e, data)}
                isClearable={true}
                isMulti
                onMenuScrollToBottom={loadMore}
                menuIsOpen={openCity}
                onMenuOpen={handleOpenCity}
                onMenuClose={handleCloseCity}
                styles={{
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  control: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                    maxHeight: "80px",
                  }),
                }}
                onInputChange={(inputValue) => setSearch(inputValue)}
              />
            </div>
            <div
              style={{
                marginRight: "15px",
                minWidth: "230px",
                maxWidth: "230px",
              }}
            >
              <Multiselect
                classNamePrefix="Select2 z-index-10"
                options={teamOptions.slice(0, visibleOptionsTeam)}
                name="teams"
                placeholder="Teams"
                onChange={(e, data) => changeTeams(e, data)}
                isSearchable
                isMulti
                styles={{
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  control: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                    maxHeight: "80px",
                  }),
                }}
                onMenuScrollToBottom={loadMoreTeam}
                onInputChange={(inputValue) => setSearchTeam(inputValue)}
              />
            </div>
            <div style={{ minWidth: "230px", maxWidth: "230px" }}>
              <Multiselect
                classNamePrefix="Select2 z-index-10"
                name="players"
                placeholder="Players"
                onChange={(e, data) => changePLayers(e, data)}
                options={playerOptions.slice(0, visibleOptionsPlayer)}
                onMenuScrollToBottom={loadMorePlayer}
                isSearchable
                isMulti
                onInputChange={(inputValue) => setSearchPlayer(inputValue)}
                styles={{
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  control: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                    maxHeight: "80px",
                  }),
                }}
              />
            </div>
          </div>
          <div
            className="custom-card text-end  pointer"
            style={{ marginRight: "18px", marginBottom: "0px" }}
          >
            <Link
              to={
                location.includes("/cricket/news/")
                  ? `/cricket/news/${"add"}`
                  : `/football/news/${"add"}`
              }
              state={{
                SeriesOptions: SeriesOptions,
                TeamOptions: TeamOptions,
                PlayersOptions: PlayersOptions,
              }}
            >
              <Button
                className="text-white"
                style={{ marginLeft: "15px", marginTop: "-3px" }}
                disabled={statusCode !== 200 && true}
                type="button"
              >
                <i className="fas fa-plus"></i> &nbsp; Add News
              </Button>
            </Link>
          </div>
        </Col>
      </div>
      <UserTable
        data={data}
        totalRows={totalRows}
        dataPerPage={dataPerPage}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        getAllUser={getAllUserData}
        handleOpen={handleOpen}
        SeriesOptions={SeriesOptions}
        TeamOptions={TeamOptions}
        PlayersOptions={PlayersOptions}
        statusCode={statusCode}
        pageNumber={pageNumber}
      />
    </>
  );
}
