import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment";
import { useFormik } from "formik";
import NotificationService from "../../services/notification.service";
import { parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

export const NotificationEdit = (props) => {
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [removeDate, setRemoveDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImageApi, setSelectedImageApi] = useState([]);
  const [update, setUpdate] = useState(false);
  const [newImage, setNewImage] = useState([]);
  const [newData, setNewData] = useState([]);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const language = [
    { name: "English", sname: "en" },
    { name: "Hindi", sname: "hi" },
    { name: "Bangla", sname: "bn" },
  ];

  const handleOpen = async () => {
    if (id !== "add") {
      setUpdate(true);

      const response = await NotificationService.getSingleNotification(
        id,
        "cricket"
      );
      const result = JSON.parse(response.data.payload);
      setNewData(result);
      Object.keys(result).forEach((lang, index) => {
        formik.setFieldValue("id", response.data.id);
        formik.setFieldValue(`languages[${index}].title`, result[lang].title);
        formik.setFieldValue(
          `languages[${index}].description`,
          result[lang].description
        );
        formik.setFieldValue(
          `languages[${index}].buttonText`,
          result[lang].buttonText
        );
        formik.setFieldValue(`languages[${index}].image`, result[lang].image);
        formik.setFieldValue("timestamp", Number(response.data.timestamp));
      });
      formik.setFieldValue("isfromfb", response.data.isfromfb);
    }
  };

  useEffect(() => {
    handleOpen();
  }, [id]);

  const initialValue = {
    languages: language.map((item) => ({
      title: "",
      description: "",
      language: item.sname,
      image: "",
      buttonText: "",
    })),
    timestamp: "",
    isfromfb: true,
  };

  const handleError = () => {
    const data = formik.values.languages;
    const updatedData = data.map((item, index) => {
      let titleError = "";
      let buttonError = "";
      let descriptionError = "";

      if (
        index === 0 &&
        item.title === "" &&
        item.description === "" &&
        item.buttonText === ""
      ) {
        titleError = "Title cannot be empty";
        descriptionError = "Description cannot be empty";
        buttonError = "Button Text cannot be empty";
      } else if (item.title === "" && item.description !== "") {
        titleError = "Title cannot be empty";
      } else if (item.title !== "" && item.description === "") {
        descriptionError = "Description cannot be empty";
      } else if (
        item.title !== "" &&
        item.description !== "" &&
        item.buttonText === ""
      ) {
        buttonError = "Button Text cannot be empty";
      }
      return {
        ...item,
        isError:
          (index === 0 &&
            item.title === "" &&
            item.description === "" &&
            item.buttonText === "") ||
          (item.title === "" && item.description !== "") ||
          (item.title !== "" && item.description === "") ||
          (item.title !== "" &&
            item.description !== "" &&
            item.buttonText === ""),
        titleError,
        buttonError,
        descriptionError,
      };
    });
    setError(updatedData);
  };

  const sendNotification = async (values) => {
    const { timestamp, languages, isfromfb } = values;
    const filteredLanguages = languages.filter(
      (lang) => lang.title !== "" && lang.description !== ""
    );

    let error = [];
    setIsSubmitting(true);
    const validateError = () => {
      const data = formik.values.languages;
      const updatedData = data.map((item, index) => {
        let titleError = "";
        let descriptionError = "";
        let buttonError = "";

        if (
          index === 0 &&
          item.title === "" &&
          item.description === "" &&
          item.buttonText === ""
        ) {
          titleError = "Title cannot be empty";
          descriptionError = "Description cannot be empty";
          buttonError = "Button Text cannot be empty";
        } else if (item.title === "" && item.description !== "") {
          titleError = "Title cannot be empty";
        } else if (item.title !== "" && item.description === "") {
          descriptionError = "Description cannot be empty";
        } else if (
          item.title !== "" &&
          item.description !== "" &&
          item.buttonText === ""
        ) {
          buttonError = "Button Text cannot be empty";
        }
        return {
          ...item,
          isError:
            (index === 0 &&
              item.title === "" &&
              item.description === "" &&
              item.buttonText === "") ||
            (item.title === "" && item.description !== "") ||
            (item.title !== "" && item.description === "") ||
            (item.title !== "" &&
              item.description !== "" &&
              item.buttonText === ""),
          titleError,
          buttonError,
          descriptionError,
        };
      });

      error = updatedData;
    };
    validateError();
    const filteredOptions = filteredLanguages.map((option) => {
      const { image, ...filteredOption } = option;
      return filteredOption;
    });

    let isError = false;

    for (let item of error) {
      if (item.isError === true) {
        isError = true;
        break;
      }
    }
    if (!isError) {
      if (!values.id) {
        try {
          setLoader(true);
          const formData = new FormData();
          formData.append("payload", JSON.stringify(filteredOptions));
          formData.append("isfromfb", isfromfb);
          if (timestamp !== null && timestamp !== "") {
            formData.append("timestamp", timestamp);
          }

          if (selectedImage["0"] !== undefined) {
            formData.append(`en_image`, selectedImageApi["0"]);
          }
          if (selectedImage["1"] !== undefined) {
            formData.append(`hi_image`, selectedImageApi["1"]);
          }
          if (selectedImage["2"] !== undefined) {
            formData.append(`bn_image`, selectedImageApi["2"]);
          }
          await NotificationService.Notification(formData, "cricket");
          enqueueSnackbar("Notification sent SuccessFully", {
            variant: "success",
          });
          setLoader(false);
          navigate(-1);
        } catch (error) {
          console.error("Error sending notification:", error.message);
        }
      } else {
        try {
          setLoader(true);
          const formData = new FormData();
          formData.append("payload", JSON.stringify(filteredOptions));
          formData.append("isfromfb", isfromfb);
          if (timestamp !== null && timestamp !== "") {
            formData.append("timestamp", timestamp);
          }
          if (selectedImage["0"] !== undefined) {
            formData.append(`en_image`, selectedImageApi["0"]);
          }
          if (selectedImage["1"] !== undefined) {
            formData.append(`hi_image`, selectedImageApi["1"]);
          }
          if (selectedImage["2"] !== undefined) {
            formData.append(`bn_image`, selectedImageApi["2"]);
          }
          await NotificationService.NotificationUpdate(
            values.id,
            formData,
            "cricket"
          );
          enqueueSnackbar("Notification update SuccessFully", {
            variant: "success",
          });
          setLoader(false);
          navigate(-1);
        } catch (error) {
          console.error("Error sending notification:", error.message);
        }
      }
    } else {
      handleError();
    }
  };

  // const handleError = () => {
  //   const data = formik.values.languages;
  //   const updatedData = data.map((item, index) => {
  //     let titleError = "";
  //     let buttonError = "";
  //     let descriptionError = "";
  //     let imageError = "";

  //     if (
  //       index === 0 &&
  //       item.title === "" &&
  //       item.description === "" &&
  //       item.image === "" &&
  //       item.buttonText === ""
  //     ) {
  //       titleError = "Title cannot be empty";
  //       descriptionError = "Description cannot be empty";
  //       imageError = "Image cannot be empty";
  //       buttonError = "Button Text cannot be empty";
  //     } else if (item.title === "" && item.description !== "") {
  //       titleError = "Title cannot be empty";
  //     } else if (item.title !== "" && item.description === "") {
  //       descriptionError = "Description cannot be empty";
  //     } else if (
  //       item.title !== "" &&
  //       item.description !== "" &&
  //       item.buttonText === ""
  //     ) {
  //       buttonError = "Button Text cannot be empty";
  //     } else if (
  //       index === 0 &&
  //       item.title !== "" &&
  //       item.description !== "" &&
  //       item.image === ""
  //     ) {
  //       imageError = "Image cannot be empty";
  //     } else if (
  //       index === 1 &&
  //       (item.title !== "" || item.description !== "") &&
  //       item.language === "hi" &&
  //       item.image === ""
  //     ) {
  //       imageError = "Image cannot be empty";
  //     } else if (
  //       index === 2 &&
  //       (item.title !== "" || item.description !== "") &&
  //       item.language === "bn" &&
  //       item.image === ""
  //     ) {
  //       imageError = "Image cannot be empty";
  //     }
  //     return {
  //       ...item,
  //       isError:
  //         (index === 0 &&
  //           item.title === "" &&
  //           item.description === "" &&
  //           item.buttonText === "" &&
  //           item.image === "") ||
  //         (item.title === "" && item.description !== "") ||
  //         (item.title !== "" && item.description === "") ||
  //         (item.title !== "" &&
  //           item.description !== "" &&
  //           item.buttonText === "") ||
  //         (item.title !== "" &&
  //           item.description !== "" &&
  //           item.buttonText !== "" &&
  //           item.image === "") ||
  //         (index === 1 &&
  //           item.title !== "" &&
  //           item.description !== "" &&
  //           item.buttonText === "" &&
  //           item.image === "") ||
  //         (index === 2 &&
  //           item.title !== "" &&
  //           item.description !== "" &&
  //           item.buttonText === "" &&
  //           item.image === "") ||
  //         (index === 0 &&
  //           item.title !== "" &&
  //           item.description !== "" &&
  //           item.buttonText !== "" &&
  //           item.image === ""),
  //       titleError,
  //       buttonError,
  //       descriptionError,
  //       imageError,
  //     };
  //   });
  //   setError(updatedData);
  // };

  // const sendNotification = async (values) => {
  //   const { timestamp, languages } = values;
  //   const filteredLanguages = languages.filter(
  //     (lang) => lang.title !== "" && lang.description !== ""
  //   );

  //   let error = [];
  //   setIsSubmitting(true);
  //   const validateError = () => {
  //     const data = formik.values.languages;
  //     const updatedData = data.map((item, index) => {
  //       let titleError = "";
  //       let descriptionError = "";
  //       let buttonError = "";
  //       let imageError = "";

  //       if (
  //         index === 0 &&
  //         item.title === "" &&
  //         item.description === "" &&
  //         item.image === "" &&
  //         item.buttonText === ""
  //       ) {
  //         titleError = "Title cannot be empty";
  //         descriptionError = "Description cannot be empty";
  //         imageError = "Image cannot be empty";
  //         buttonError = "Button Text cannot be empty";
  //       } else if (item.title === "" && item.description !== "") {
  //         titleError = "Title cannot be empty";
  //       } else if (item.title !== "" && item.description === "") {
  //         descriptionError = "Description cannot be empty";
  //       } else if (
  //         item.title !== "" &&
  //         item.description !== "" &&
  //         item.buttonText === ""
  //       ) {
  //         buttonError = "Button Text cannot be empty";
  //       } else if (
  //         index === 0 &&
  //         item.title !== "" &&
  //         item.description !== "" &&
  //         item.image === ""
  //       ) {
  //         imageError = "Image cannot be empty";
  //       } else if (
  //         index === 1 &&
  //         (item.title !== "" || item.description !== "") &&
  //         item.language === "hi" &&
  //         item.image === ""
  //       ) {
  //         imageError = "Image cannot be empty";
  //       } else if (
  //         index === 2 &&
  //         (item.title !== "" || item.description !== "") &&
  //         item.language === "bn" &&
  //         item.image === ""
  //       ) {
  //         imageError = "Image cannot be empty";
  //       }
  //       return {
  //         ...item,
  //         isError:
  //           (index === 0 &&
  //             item.title === "" &&
  //             item.description === "" &&
  //             item.image === "" &&
  //             item.buttonText === "") ||
  //           (item.title === "" && item.description !== "") ||
  //           (item.title !== "" && item.description === "") ||
  //           (item.title !== "" &&
  //             item.description !== "" &&
  //             item.buttonText === "") ||
  //           (item.title !== "" &&
  //             item.description !== "" &&
  //             item.buttonText !== "" &&
  //             item.image === "") ||
  //           (index === 1 &&
  //             item.title !== "" &&
  //             item.description !== "" &&
  //             item.image === "") ||
  //           (index === 2 &&
  //             item.title !== "" &&
  //             item.description !== "" &&
  //             item.image === "") ||
  //           (index === 0 &&
  //             item.title !== "" &&
  //             item.description !== "" &&
  //             item.buttonText !== "" &&
  //             item.image === ""),
  //         titleError,
  //         buttonError,
  //         descriptionError,
  //         imageError,
  //       };
  //     });

  //     error = updatedData;
  //   };
  //   validateError();
  //   const filteredOptions = filteredLanguages.map((option) => {
  //     const { image, ...filteredOption } = option;
  //     return filteredOption;
  //   });

  //   let isError = false;

  //   for (let item of error) {
  //     if (item.isError === true) {
  //       isError = true;
  //       break;
  //     }
  //   }
  //   if (!isError) {
  //     if (!values.id) {
  //       try {
  //         setLoader(true);
  //         const formData = new FormData();
  //         formData.append("payload", JSON.stringify(filteredOptions));
  //         if (timestamp !== null && timestamp !== "") {
  //           formData.append("timestamp", timestamp);
  //         }
  //         if (selectedImage["0"] !== undefined) {
  //           formData.append(`en_image`, selectedImageApi["0"]);
  //         }
  //         if (selectedImage["1"] !== undefined) {
  //           formData.append(`hi_image`, selectedImageApi["1"]);
  //         }
  //         if (selectedImage["2"] !== undefined) {
  //           formData.append(`bn_image`, selectedImageApi["2"]);
  //         }
  //         await NotificationService.Notification(formData, "cricket");
  //         enqueueSnackbar("Notification sent SuccessFully", {
  //           variant: "success",
  //         });
  //         setLoader(false);
  //         navigate(-1);
  //       } catch (error) {
  //         console.error("Error sending notification:", error.message);
  //       }
  //     } else {
  //       try {
  //         setLoader(true);
  //         const formData = new FormData();
  //         formData.append("payload", JSON.stringify(filteredOptions));
  //         if (timestamp !== null && timestamp !== "") {
  //           formData.append("timestamp", timestamp);
  //         }
  //         if (selectedImage["0"] !== undefined) {
  //           formData.append(`en_image`, selectedImageApi["0"]);
  //         }
  //         if (selectedImage["1"] !== undefined) {
  //           formData.append(`hi_image`, selectedImageApi["1"]);
  //         }
  //         if (selectedImage["2"] !== undefined) {
  //           formData.append(`bn_image`, selectedImageApi["2"]);
  //         }
  //         await NotificationService.NotificationUpdate(
  //           values.id,
  //           formData,
  //           "cricket"
  //         );
  //         enqueueSnackbar("Notification update SuccessFully", {
  //           variant: "success",
  //         });
  //         setLoader(false);
  //         navigate(-1);
  //       } catch (error) {
  //         console.error("Error sending notification:", error.message);
  //       }
  //     }
  //   } else {
  //     handleError();
  //   }
  // };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: sendNotification,
  });

  useEffect(() => {
    if (isSubmitting) {
      handleError();
    }
  }, [formik.values]);

  const deleteHandler = (id, index) => {
    setSelectedImage((prevImages) => {
      const updatedImagesApi = [...prevImages];
      updatedImagesApi[index] = "";
      return updatedImagesApi;
    });
    setSelectedImageApi((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = "";
      return updatedImages;
    });

    formik.setFieldValue(`languages[${index}].image`, "");
    const fileInput = document.getElementById(`fileInput-${index}`);
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleChange = (e, index) => {
    setNewImage((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = true;
      return updatedImages;
    });
    const selectedFile = e.currentTarget.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);
    setSelectedImage((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = imageUrl;
      return updatedImages;
    });
    setSelectedImageApi((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = selectedFile;
      return updatedImages;
    });
    formik.setFieldValue(`languages[${index}].image`, selectedFile);
  };

  const formatDate = (date) => {
    return `${date}`;
  };

  const handleDateChnage = (date) => {
    setRemoveDate(true);
    const newDate = formatDate(date);
    const dateShow = new Date();
    dateShow.setMilliseconds(0);
    dateShow.setSeconds(0);
    const newDateInMilliseconds = parse(
      newDate,
      "dd-MM-yyyy HH:mm",
      new Date()
    ).getTime();
    formik.setFieldValue(
      "timestamp",
      newDateInMilliseconds === dateShow.getTime()
        ? null
        : newDateInMilliseconds
    );
  };

  const handleClearDate = () => {
    setRemoveDate(false);
    formik.setFieldValue("timestamp", null);
  };

  const handleAODChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue("isfromfb", Boolean(checked));
  };

  return (
    <>
      <div className="page-header2"></div>
      <div
        className="me-auto d-flex justify-content-start"
        style={{ fontWeight: 600, fontSize: "20px", marginBottom: "10px" }}
      >
        SEND NOTIFICATION
      </div>
      <Row>
        <Card className="custom-card">
          <form onSubmit={formik.handleSubmit}>
            <Card.Body>
              <Accordion defaultActiveKey={0}>
                {language.map((item, index) => (
                  <Accordion.Item
                    eventKey={index}
                    key={index}
                    className="accodian-items"
                    style={{
                      border: error[index]?.isError
                        ? "1px solid red"
                        : "1px solid #EAEDF7",
                    }}
                  >
                    <Accordion.Header className="accodian-headers">
                      {item.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <FormGroup className="form-group">
                        <Row className=" row-sm">
                          <Col md={2} className="d-flex align-items-start mt-2">
                            <Form.Label className="text-dark mt-0">
                              {"Title"}
                            </Form.Label>
                          </Col>
                          <Col
                            md={10}
                            className="d-flex flex-column align-items-start"
                          >
                            <Form.Control
                              placeholder="Title"
                              name={`languages[${index}].title`}
                              onChange={formik.handleChange}
                              value={formik.values.languages[index].title}
                            />
                            <p className="text-start error">
                              {error[index]?.isError === true
                                ? error[index]?.titleError !== "" &&
                                  error[index]?.titleError
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Row className=" row-sm">
                          <Col md={2} className="d-flex align-items-start mt-2">
                            <Form.Label className="text-dark mt-0">
                              {"Message"}
                            </Form.Label>
                          </Col>
                          <Col
                            md={10}
                            className="d-flex flex-column align-items-start"
                          >
                            <Form.Control
                              placeholder="Message"
                              name={`languages[${index}].description`}
                              onChange={formik.handleChange}
                              value={formik.values.languages[index].description}
                            />
                            <p className="text-start error">
                              {error[index]?.isError === true
                                ? error[index]?.descriptionError !== "" &&
                                  error[index]?.descriptionError
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Row className=" row-sm">
                          <Col md={2} className="d-flex align-items-start mt-2">
                            <Form.Label className="text-dark mt-0">
                              {"Button Text"}
                            </Form.Label>
                          </Col>
                          <Col
                            md={10}
                            className="d-flex flex-column align-items-start"
                          >
                            <Form.Control
                              placeholder="Button Text"
                              type="text"
                              name={`languages[${index}].buttonText`}
                              onChange={formik.handleChange}
                              value={formik.values.languages[index].buttonText}
                            />
                            <p className="text-start error">
                              {error[index]?.isError === true
                                ? error[index]?.buttonError !== "" &&
                                  error[index]?.buttonError
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Row className=" row-sm">
                          <Col md={2} className="d-flex align-items-start mt-2">
                            <Form.Label className="text-dark mt-0">
                              {"File"}
                            </Form.Label>
                          </Col>
                          <Col
                            md={6}
                            className="d-flex flex-column align-items-start"
                          >
                            <Form.Control
                              type="file"
                              id={`fileInput-${index}`}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <p className="text-start error">
                              {error[index]?.isError === true
                                ? error[index]?.imageError !== "" &&
                                  error[index]?.imageError
                                : ""}
                            </p>
                            {update ? (
                              // <div className="pos-relative">
                              <img
                                key={index}
                                src={
                                  newImage[index]
                                    ? selectedImage[index]
                                    : formik.values.languages[index].image
                                }
                                style={{
                                  marginTop: "10px",
                                  height:
                                    newImage[index] ||
                                    formik.values.languages[index].image
                                      ? "300px"
                                      : "0px",
                                }}
                                alt=""
                              />
                            ) : (
                              <div className="pos-relative">
                                <img
                                  src={selectedImage[index]}
                                  style={{
                                    marginTop: "10px",
                                    height: selectedImage[index]
                                      ? "300px"
                                      : "0px",
                                    width: "450px",
                                  }}
                                  alt=""
                                />
                                {selectedImage[index] && (
                                  <button
                                    onClick={() =>
                                      deleteHandler(selectedImage[index], index)
                                    }
                                    style={{
                                      right: "0px",
                                      top: "10px",
                                      position: "absolute",
                                    }}
                                    className="btn btn-primary"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
            <Card.Footer>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
                  gap: 50,
                }}
              >
                <div className="d-flex align-items-center">
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      fontWeight: "500",
                      marginRight: "10px",
                    }}
                  >
                    Notification Schedule
                  </Form.Label>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // maxWidth: "140px",
                      // minWidth: "140px",
                    }}
                  >
                    <DatePicker
                      style={{
                        padding: "18px",
                        border: "1px solid #e8e8f7",
                        textAlign: "start",
                        maxWidth: "190px",
                        minWidth: "190px",
                        paddingLeft: "10px",
                      }}
                      value={moment(formik.values.timestamp).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                      onChange={(date) => handleDateChnage(date)}
                      format="DD-MM-YYYY HH:mm"
                      placeholder="Notification schdule time"
                      name="timestamp"
                      minDate={new Date()}
                      plugins={[
                        <TimePicker
                          format="HH:mm"
                          hideSeconds
                          position="top"
                        />,
                      ]}
                    />
                    {removeDate && (
                      <span
                        onClick={handleClearDate}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      >
                        <i className="fas fa-close"></i>
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      fontWeight: "500",
                      marginRight: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    Is Aod
                  </Form.Label>

                  {newData != "" && (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      defaultChecked={formik.values.isfromfb}
                      onChange={handleAODChange}
                    />
                  )}

                  {newData == "" && (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      defaultChecked={formik.values.isfromfb}
                      onChange={handleAODChange}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{ marginTop: "15px", marginRight: "15px" }}
                  onClick={() => navigate(-1)}
                  type="button"
                  className="btn btn-danger"
                >
                  CANCEL
                </button>
                <Button style={{ marginTop: "15px" }} type="submit">
                  {loader ? (
                    <CircularProgress
                      size={20}
                      style={{
                        textAlign: "center",
                        marginTop: "4px",
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "SEND NOTIFICATION"
                  )}
                </Button>
              </div>
            </Card.Footer>
          </form>
        </Card>
      </Row>
    </>
  );
};
