import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import { DashboardTable } from "./app/component/news/newsListTable";
import Notification from "./app/component/cricketNotification/notification";
import FootballNotification from "./app/component/footballNotification/footballNotification";
import { FootballNotificationEdit } from "./app/component/footballNotification/footballNotificationModule";
import { NotificationEdit } from "./app/component/cricketNotification/notificationModule";
import { UsreEdit } from "./app/component/news/userPredictionList";
import { FootballNews } from "./app/component/news/footballNews";
import FootballStoryList from "./app/component/story/footballStoryList";
import CricketStoryList from "./app/component/story/cricketStoryList";
import Cricketstory from "./app/component/story/cricketstory";
import FootballStory from "./app/component/story/footballStory";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/cricket/news/" />,
      },
      {
        path: "/cricket/news/",
        element: <DashboardTable />,
      },
      {
        path: "football/news/",
        element: <DashboardTable />,
      },
      {
        path: "/cricket/notification/",
        element: <Notification />,
      },
      {
        path: "/football/notification/",
        element: <FootballNotification />,
      },
      {
        path: "/football-notification/:id",
        element: <FootballNotificationEdit />,
      },
      {
        path: "/football/story/",
        element: <FootballStoryList />,
      },
      {
        path: "/football/story/:id",
        element: <FootballStory />,
      },
      {
        path: "/cricket-notification/:id",
        element: <NotificationEdit />,
      },
      {
        path: "/cricket/news/:id",
        element: <UsreEdit />,
      },
      {
        path: "/football/news/:id",
        element: <FootballNews />,
      },
      {
        path: "/cricket/story/",
        element: <CricketStoryList />,
      },
      {
        path: "/cricket/story/:id",
        element: <Cricketstory />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/cricket/news/" />
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
