import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Card, Modal } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import NotificationService from "../../services/notification.service";
import NewsService from "../../services/user.service";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMediaQuery } from "../../hooks/mediaQueryHook";
import StoryService from "../../services/story.service";

export const UserTable = (props) => {
  const location = window.location.pathname;
  const {
    data,
    totalRows,
    dataPerPage,
    handlePageChange,
    handlePerPageChange,
    isLoading,
    getAllUser,
    PlayersOptions,
    TeamOptions,
    SeriesOptions,
    statusCode,
    pageNumber,
  } = props;
  const isTablet = useMediaQuery("(max-width: 991px)");
  const isTabletMore = useMediaQuery("(max-width: 1400px)");
  const isTabletMoreLaptop = useMediaQuery("(max-width: 1650px)");
  const isTabletMore1800 = useMediaQuery("(min-width: 1800px)");
  const isTabletMore1500 = useMediaQuery("(max-width: 1854px)");

  const [modalShows, setModalShows] = useState(false);
  const [dataModel, setDataModel] = useState("");
  const handleClose = () => setModalShows(false);

  const handleOpenDelete = (data) => {
    setModalShows(true);
    setDataModel(data);
  };

  const columns = [
    {
      name: <b>SHORT DESCRIPTION</b>,
      selector: (row) => (
        <>
          <span title={row.shortdescription}>{row.shortdescription}</span>
        </>
      ),
      sortable: true,
      width: isTabletMore
        ? "270px"
        : isTabletMoreLaptop
        ? "270px"
        : isTabletMore1800
        ? "430px"
        : "300px",
    },
    {
      name: <b>LONG DESCRIPTION</b>,
      selector: (row) => (
        <>
          <span title={row.longdescription?.replace(/(<([^>]+)>)/gi, "")}>
            {row.longdescription?.replace(/(<([^>]+)>)/gi, "")}
          </span>
        </>
      ),
      sortable: true,
      width: isTabletMore
        ? "350px"
        : isTabletMoreLaptop
        ? "370px"
        : isTabletMore1800
        ? "530px"
        : "400px",
    },
    {
      name: <b>PHOTO</b>,
      selector: (row) => <img alt="" src={row.image} />,
      sortable: true,
      width: "100px",
    },
    {
      name: <b>CREATED AT</b>,
      selector: (row) => (
        <div title={moment(row.createdat).format("DD-MM-YYYY hh:mm")}>
          {moment(row.createdat).format("DD-MM-YYYY hh:mm")}
        </div>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <>
          <div className="d-flex">
            <div className="justify-content-center">
              <Link
                to={
                  location.includes("/cricket/news/")
                    ? `/cricket/news/${row.id}`
                    : `/football/news/${row.id}`
                }
                state={{
                  SeriesOptions: SeriesOptions,
                  TeamOptions: TeamOptions,
                  PlayersOptions: PlayersOptions,
                }}
              >
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn btn-primary btn-sm"
                  disabled={statusCode !== 200 && true}
                >
                  <i className="fa fa-pencil"></i>
                </button>
              </Link>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-danger btn-sm"
                onClick={() => handleOpenDelete(row)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </>
      ),
      width: "150px",
    },
  ];

  const extensions = useMemo(
    () => ({
      filter: true,
    }),
    []
  );

  return (
    <>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <p></p>
                  <DataTable
                    {...extensions}
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                    paginationPerPage={dataPerPage}
                    paginationDefaultPage={pageNumber}
                    onChangeRowsPerPage={(page) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
        {modalShows && (
          <DeleteNews
            show={modalShows}
            onHide={handleClose}
            dataModel={dataModel}
            getAllUserData={getAllUser}
          />
        )}
      </div>
    </>
  );
};

export const NotificationTable = (props) => {
  const {
    data,
    totalRows,
    dataPerPage,
    handlePageChange,
    handlePerPageChange,
    isLoading,
    getAllUserData,
    type,
    pageNumber,
  } = props;
  const [modalShows, setModalShows] = useState(false);
  const [dataModel, setDataModel] = useState("");
  const handleOpenDelete = (data) => {
    setModalShows(true);
    setDataModel(data);
  };

  const naviagate = useNavigate();

  const navigation = (id) => {
    if (type === "football") {
      naviagate(`/football-notification/${id}`);
    } else {
      naviagate(`/cricket-notification/${id}`);
    }
  };
  const handleClose = () => setModalShows(false);

  const columns = [
    {
      name: <b>TITLE</b>,
      selector: (row) => (
        <>
          <span title={row.payload.title}>{row.payload.title}</span>
        </>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: <b>DESCRIPTION</b>,
      selector: (row) => (
        <>
          <span title={row.payload.description}>{row.payload.description}</span>
        </>
      ),
      sortable: true,
      width: "400px",
    },
    {
      name: <b>PHOTO</b>,
      selector: (row) => <img alt="" src={row.payload.image} />,
      sortable: true,
      width: "100px",
    },
    {
      name: <b>SCHEDULE</b>,
      selector: (row) => (
        <div title={moment(Number(row.timestamp)).format("DD-MM-YYYY hh:mm")}>
          {moment(Number(row.timestamp)).format("DD-MM-YYYY hh:mm")}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <>
          <div className="d-flex">
            <div className="justify-content-center">
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-primary btn-sm"
                onClick={() => navigation(row.id)}
              >
                <i className="fa fa-pencil"></i>
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-danger btn-sm"
                onClick={() => handleOpenDelete(row)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </>
      ),
      width: "190px",
    },
  ];

  const extensions = useMemo(
    () => ({
      filter: true,
    }),
    []
  );

  return (
    <>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <p></p>
                  <DataTable
                    {...extensions}
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                    paginationPerPage={dataPerPage}
                    paginationDefaultPage={pageNumber}
                    onChangeRowsPerPage={(page) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
        {modalShows && (
          <DeleteNotification
            show={modalShows}
            type={type}
            onHide={handleClose}
            dataModel={dataModel}
            getAllUserData={getAllUserData}
          />
        )}
      </div>
    </>
  );
};

export const StoryTable = (props) => {
  const {
    data,
    totalRows,
    dataPerPage,
    handlePageChange,
    handlePerPageChange,
    isLoading,
    getAllStory,
    type,
    pageNumber,
  } = props;
  const naviagate = useNavigate();

  const navigation = (id) => {
    if (type === "cricket") {
      naviagate(`/cricket/story/${id}`);
    } else {
      naviagate(`/football/story/${id}`);
    }
  };

  const [modalShows, setModalShows] = useState(false);
  const [dataModel, setDataModel] = useState("");
  const handleClose = () => setModalShows(false);

  const handleOpenDelete = (data) => {
    setModalShows(true);
    setDataModel(data);
  };

  const columns = [
    {
      name: <b>IMAGE</b>,
      selector: (row) => (
        <img
          alt="image"
          src={row.image}
          width={100}
          height={70}
          style={{ objectFit: "cover", padding: "5px" }}
        />
      ),
      sortable: true,
    },
    {
      name: <b>TEXT</b>,
      selector: (row) => (
        <>
          <span title={row.text}>{row.text}</span>
        </>
      ),
      sortable: true,
    },
    {
      name: <b>CREATED DATE</b>,
      selector: (row) => (
        <>
          <span title={moment(row.createdat).format("DD/MM/YYYY")}>
            {moment(row.createdat).format("DD/MM/YYYY")}
          </span>
        </>
      ),
      sortable: true,
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <>
          <div className="d-flex">
            <div className="justify-content-center">
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-primary btn-sm"
                onClick={() => navigation(row.id)}
              >
                <i className="fa fa-pencil"></i>
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-danger btn-sm"
                onClick={() => handleOpenDelete(row)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </>
      ),
      width: "190px",
    },
  ];

  return (
    <div lg={12} className="w-100">
      <Card className="custom-card overflow-hidden">
        <Card.Body>
          <div className="responsive">
            <Card className="custom-card overflow-hidden">
              <div>
                <p></p>
                <DataTable
                  data={data}
                  columns={columns}
                  noHeader
                  persistTableHead
                  pagination
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  highlightOnHover
                  center={true}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[5, 10, 50, 100]}
                  paginationPerPage={dataPerPage}
                  paginationDefaultPage={pageNumber}
                  onChangeRowsPerPage={(page) => {
                    handlePerPageChange(page, 1);
                  }}
                  onChangePage={handlePageChange}
                  progressPending={isLoading}
                  progressComponent={
                    <CircularProgress size={30} style={{ marginTop: "50px" }} />
                  }
                />
              </div>
            </Card>
          </div>
        </Card.Body>
      </Card>
      {modalShows && (
        <DeleteStory
          show={modalShows}
          onHide={handleClose}
          dataModel={dataModel}
          getAllStory={getAllStory}
          type={type}
        />
      )}
    </div>
  );
};

export const DeleteNotification = (props) => {
  const { onHide, dataModel, getAllUserData, type } = props;
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRace = async () => {
    setLoader(true);
    await NotificationService.deleteNotification(dataModel.id, type);
    enqueueSnackbar("Notification deleted SuccessFully", {
      variant: "success",
    });
    onHide();
    getAllUserData();
    setLoader(false);
  };
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

        <h4 className="tx-danger tx-semibold mg-b-20">Delete !</h4>
        <p className="mg-b-20 mg-x-20 ">
          are you sure want to delete <strong>{dataModel.payload.title}</strong>{" "}
          notification !!
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="danger" onClick={deleteRace}>
            {loader ? (
              <CircularProgress
                size={20}
                style={{ textAlign: "center", marginTop: "4px", color: "#fff" }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const DeleteNews = (props) => {
  const { onHide, dataModel, getAllUserData } = props;
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRace = async () => {
    setLoader(true);
    await NewsService.DeleteNews(dataModel.id, dataModel.apptype);
    enqueueSnackbar("News deleted SuccessFully", { variant: "success" });
    onHide();
    getAllUserData();
    setLoader(false);
  };
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

        <h4 className="tx-danger tx-semibold mg-b-20">Delete !</h4>
        <p className="mg-b-20 mg-x-20 ">
          are you sure want to delete{" "}
          <strong> {dataModel.shortdescription}</strong> news !!
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="danger" onClick={deleteRace}>
            {loader ? (
              <CircularProgress
                size={20}
                style={{ textAlign: "center", marginTop: "4px", color: "#fff" }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const DeleteStory = (props) => {
  const { onHide, dataModel, getAllStory, type } = props;
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRace = async () => {
    setLoader(true);
    if (type === "cricket") {
      await StoryService.deleteStory(dataModel.id);
    } else {
      await StoryService.deleteFootballStory(dataModel.id);
    }
    enqueueSnackbar("Story Deleted SuccessFully", { variant: "success" });
    onHide();
    getAllStory();
    setLoader(false);
  };
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

        <h4 className="tx-danger tx-semibold mg-b-20">Delete !</h4>
        <p className="mg-b-20 mg-x-20 ">
          Are you sure want to delete <strong> {dataModel.text}</strong> Story
          !!
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="danger" onClick={deleteRace}>
            {loader ? (
              <CircularProgress
                size={20}
                style={{ textAlign: "center", marginTop: "4px", color: "#fff" }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
