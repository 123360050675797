import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import AuthenticationService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { setLoggedIn } from "../store/slice/auth";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
    oldPassword: yup.string().required("Please enter your old password"),
    newPassword: yup.string().required("Please enter your new password")
});


export const ChangePass = (props) => {
    const { onHide } = props;
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const submit = async (values) => {
        try {
            if (values.oldPassword !== values.newPassword) {
                await AuthenticationService.changePassWord(values)
                localStorage.clear();
                dispatch(setLoggedIn(false));
                localStorage.setItem("isLoggedIn", JSON.stringify(false));
                navigate('/login');
                enqueueSnackbar("Password change successFully", { variant: "success" });
            } else {
                enqueueSnackbar("Please enter diffrent password", { variant: "error" });
            }
        } catch (error) {
            console.error('error', error)
            enqueueSnackbar(error.response.data.message, { variant: "error" });
        }
    }

    const initialValues = {
        oldPassword: "",
        newPassword: ""
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: submit
    })
    return (
        <Modal
            {...props}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton  ><div style={{ marginLeft: '30%' }} ><h4>Change Passsword</h4></div></Modal.Header>
            <Modal.Body className="text-center">
                <form onSubmit={formik.handleSubmit}>
                    {/*  */}
                    <Form.Label
                        style={{
                            textAlign: "start",
                            color: "#000",
                            marginTop: "15px",
                        }}
                    >
                        Old Password
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="oldPassword"
                        placeholder="Old Password"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.oldPassword && formik.touched.oldPassword ? (
                        <p className="text-start error">{formik.errors.oldPassword}</p>
                    ) : null}

                    <Form.Label
                        style={{
                            textAlign: "start",
                            color: "#000",
                            marginTop: "15px",
                        }}
                    >
                        New Password
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="newPassword"
                        placeholder="New Password"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.newPassword && formik.touched.newPassword ? (
                        <p className="text-start error">{formik.errors.newPassword}</p>
                    ) : null}

                    <div className="d-flex ms-auto" style={{ justifyContent: 'end' }}>
                        <button
                            style={{
                                display: "flex",
                                marginRight: "15px",
                                marginTop: "15px",
                            }}
                            onClick={() => onHide()}
                            className="btn btn-danger"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            style={{
                                display: "flex",
                                marginRight: "15px",
                                marginTop: "15px",
                            }}
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};
