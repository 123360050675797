import { useState } from "react";

function useMenuState(initialState) {
  const [isOpen, setIsOpen] = useState(initialState);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return [isOpen, handleOpen, handleClose];
}

export default useMenuState;