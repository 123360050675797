import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_KEY;


const encrypt = (strToEncrypt) => {
    const data = CryptoJS.enc.Utf8.parse(strToEncrypt);
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);

    if (typeof strToEncrypt === "object") {
        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(strToEncrypt),
            key,
            {
                algorithm: "AES",
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const encryptedText = encryptedData.toString();

        return encryptedText;
    } else {
        const encryptedData = CryptoJS.AES.encrypt(data, key, {
            algorithm: "AES",
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });

        const encryptedText = encryptedData.toString();
        return encryptedText;
    }
};

const decrypt = (strToDecrypt) => {
    try {
        const ciphertext = CryptoJS.enc.Base64.parse(strToDecrypt);
        const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
        const decryptedData = CryptoJS.AES.decrypt({ ciphertext }, key, {
            algorithm: "AES",
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return decryptedData.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        console.log("Error while decrypting: ", e.toString());
    }
};

const EncryptDecryptService = {
    encrypt,
    decrypt,
};
export default EncryptDecryptService;
