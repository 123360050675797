import axios from "axios";
import config from "./config";
import EncryptDecryptServiceFootball from "./app/services/encryptdecryptfootball.service";

const BASE_URL = config.baseApiDropDownFootball;
const appName = process.env.REACT_APP_FOOTBALL_NAME;
const packageName = process.env.REACT_APP_FOOTBALL_PACKEGENAME;

const customAxiosDroDownFootball = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  const newDate = new Date().getTime();

  if (request.data !== undefined && request.data !== null) {
    const encryptedData = EncryptDecryptServiceFootball.encrypt(request.data);
    request.data = encryptedData;
  }
  const combinedString = `${newDate}##${packageName}`;
  const signature = EncryptDecryptServiceFootball.encrypt(combinedString);

  request.headers["signature"] = signature;
  request.headers["ts"] = newDate;
  request.headers["X-Client-Identifier"] = appName;

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 403) {
    window.location = "/";
    localStorage.removeItem("token");
  }
  if (response.data) {
    const decryptedData = EncryptDecryptServiceFootball.decrypt(response.data);
    response.data = decryptedData;
  }
  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = "/";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxiosDroDownFootball.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxiosDroDownFootball.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxiosDroDownFootball;


// import axios from "axios";
// import config from "./config";
// import CryptoJS from "crypto-js";
// import EncryptDecryptService from "./app/services/encryptdecrypt.service";

// // const BASE_URL = config.baseApi;
// // const secretKey = process.env.NEXT_PUBLIC_NEXT_APP_SECRET_KEY;
// // const appName = process.env.NEXT_PUBLIC_NEXT_APP_APPNAME;
// // const packageName = process.env.NEXT_PUBLIC_NEXT_APP_PACKAGENAME;
// const BASE_URL = config.baseApiDropDownFootball;
// const appName = process.env.REACT_APP_FOOTBALL_NAME;
// const packageName = process.env.REACT_APP_FOOTBALL_PACKEGENAME;

// const customAxiosDroDownFootball = axios.create({
//   baseURL: BASE_URL,
// });

// const requestHandler = (request) => {
//   const newDate = new Date().getTime();

//   if (request.data !== undefined && request.data !== null) {
//     const encryptedData = EncryptDecryptService.encrypt(request.data);

//     request.data = encryptedData;
//   }

//   const combinedString = `${newDate}##${packageName}`;
//   const signature = EncryptDecryptService.encrypt(combinedString);

//   request.headers["signature"] = signature;
//   request.headers["ts"] = newDate;
//   request.headers["X-Client-Identifier"] = appName;
//   return request;
// };

// const responseHandler = (response) => {
//   if (response.status === 401 || response.status === 403) {
//     // window.location = "/";
//   }
//   if (response.data) {
//     const decryptedData = EncryptDecryptService.decrypt(response.data);
//     console.log("🚀 ~ responseHandler ~ decryptedData:", decryptedData)

//     // const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
//     // response.data = decryptedData;
//   }

//   return response;
// };

// const requestErrorHandler = (error) => {
//   return Promise.reject(error);
// };

// const responseErrorHandler = (error) => {
//   if (error.response) {
//     if (error.response.status === 401 || error.response.status === 403) {
//       // window.location = "/";
//       return Promise.reject(error);
//     }
//   }
//   return Promise.reject(error);
// };

// customAxiosDroDownFootball.interceptors.request.use(
//   (request) => requestHandler(request),
//   (error) => requestErrorHandler(error)
// );

// customAxiosDroDownFootball.interceptors.response.use(
//   (response) => responseHandler(response),
//   responseErrorHandler
// );

// export default customAxiosDroDownFootball;