import React, { useEffect, useRef, useState } from "react";
import Multiselect from "react-select";
import { Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Box, CircularProgress } from "@mui/material";
import { useMediaQuery } from "../../hooks/mediaQueryHook";
import NewsService from "../../services/user.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import NotificationService from "../../services/notification.service";
import useMenuState from "../../hooks/useMenuState";
import { useSnackbar } from "notistack";

const schema = yup.object().shape({
  credit: yup.string().required("Please enter credit detail"),
  shortdescription: yup.string().required("Please enter short description"),
  longdescription: yup.string().required("Please enter long description"),
  files: yup.mixed().required("Please select file"),
});

export const FootballNews = () => {
  const [loader, setLoader] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [player, setPlayer] = useState([]);
  const [playerMap, setPlayerMap] = useState([]);
  const [playerMapUpdate, setPlayerMapUpdate] = useState([]);
  const [seriesMapUpdate, setSeriesMapUpdate] = useState([]);
  const [teamsMapUpdate, setTeamsMapUpdate] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesMap, setSeriesMap] = useState([]);
  const [teamsMap, setTeamsMap] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [visibleOptions, setVisibleOptions] = useState(10);
  const [visibleOptionsTeam, setVisibleOptionsTeam] = useState(10);
  const [visibleOptionsPlayer, setVisibleOptionsPlayer] = useState(10);
  const [search, setSearch] = useState("");
  const [searchTeam, setSearchTeam] = useState("");
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [searchPlayer, setSearchPlayer] = useState("");
  const isTablet = useMediaQuery("(max-width: 991px)");
  const isTabletMore = useMediaQuery("(max-width: 1393px)");
  const isTabletMoreLaptop = useMediaQuery("(max-width: 1854px)");
  const [mergeData, setMergeData] = useState([]);
  const [removedValue, setRemovedValue] = useState("");
  const [mergeDataAdd, setMergeDataAdd] = useState([]);
  const [loaderTag, setLoaderTag] = useState(false);
  const location = window.location.pathname;
  const { id } = useParams();
  const statusRef = useRef("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const SeriesOptions = state.SeriesOptions;
  const PlayersOptions = state.PlayersOptions;
  const TeamOptions = state.TeamOptions;

  useEffect(() => {
    if (location.includes("/cricket/news/")) {
      statusRef.current = "cricket";
    } else {
      statusRef.current = "football";
    }
  }, [location]);

  useEffect(() => {
    const data = [...playerMapUpdate, ...seriesMapUpdate, ...teamsMapUpdate];
    const prevData = mergeData.filter((item) => item.label !== removedValue);
    setMergeData([...prevData, ...data]);
  }, [seriesMapUpdate, teamsMapUpdate, playerMapUpdate]);

  const handleNotification = (e) => {
    formik.setFieldValue("sendnotification", e.target.checked);
  };

  const submit = async (values, action) => {
    const {
      id,
      credit,
      files,
      longdescription,
      shortdescription,
      players,
      teams,
      series,
      sendnotification,
      metatitle,
      metadescription,
      metakeywords,
    } = values;
    if (values.id) {
      try {
        const formData = new FormData();
        formData.append("credit", credit);
        if (location.includes("/cricket/news")) {
          formData.append("apptype", "CRICKET");
        } else {
          formData.append("apptype", "FOOTBALL");
        }
        formData.append("files", files);
        formData.append("longdescription", longdescription);
        formData.append("sendnotification", sendnotification);
        formData.append("shortdescription", shortdescription);
        formData.append("metatitle", metatitle);
        formData.append("metadescription", metadescription);
        formData.append("metakeywords", metakeywords);
        mergeData.length > 0 &&
          mergeData.map((item) =>
            item.series === "S" && typeof item.value === "number"
              ? formData.append("tag", `S#${item.value}`)
              : String(item.value).startsWith("S#") &&
                formData.append("tag", item.value)
          );
        mergeData.length > 0 &&
          mergeData.map((item) =>
            item.series === "P" && typeof item.value === "number"
              ? formData.append("tag", `P#${item.value}`)
              : String(item.value).startsWith("P#") &&
                formData.append("tag", item.value)
          );
        mergeData.length > 0 &&
          mergeData.map((item) =>
            item.series === "T" && typeof item.value === "number"
              ? formData.append("tag", `T#${item.value}`)
              : String(item.value).startsWith("T#") &&
                formData.append("tag", item.value)
          );
        setLoader(true);
        await NewsService.UpdateNews(id, formData, "football");
        enqueueSnackbar("News update successFully", { variant: "success" });
        setLoader(false);
        navigate(-1);
        action.resetForm();
      } catch (error) {
        console.error("create race error", error);
        setLoader(false);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("credit", credit);
        if (location.includes("/cricket/news/add")) {
          formData.append("apptype", "CRICKET");
        } else {
          formData.append("apptype", "FOOTBALL");
        }
        formData.append("files", files);
        formData.append("sendnotification", sendnotification);
        formData.append("longdescription", longdescription);
        formData.append("shortdescription", shortdescription);
        formData.append("metatitle", metatitle);
        formData.append("metadescription", metadescription);
        formData.append("metakeywords", metakeywords);
        series.length > 0 &&
          series.map((item) => formData.append("tag", `S#${item}`));
        players.length > 0 &&
          players.map((item) => formData.append("tag", `P#${item}`));
        teams.length > 0 &&
          teams.map((item) => formData.append("tag", `T#${item}`));
        setLoader(true);
        await NewsService.AddNews("football", formData);
        enqueueSnackbar("News add successFully", { variant: "success" });
        setLoader(false);
        navigate(-1);
        action.resetForm();
      } catch (error) {
        console.error("create race error", error);
        setLoader(false);
      }
    }
  };

  const initialValue = {
    tag: null,
    credit: "",
    longdescription: "",
    shortdescription: "",
    apptype: "",
    files: null,
    sendnotification: false,
    series: [],
    teams: [],
    players: [],
    metatitle: "",
    metadescription: "",
    metakeywords: "",
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: !update && schema,
    onSubmit: submit,
  });

  const handleHashtagRemove = (index, id) => {
    if (update) {
      const updatedSeries = formik.values.series.filter((item) => {
        return item !== id;
      });
      const updatedTeams = formik.values.teams.filter((item) => {
        return item !== id;
      });
      const updatedPlayer = formik.values.players.filter((item) => {
        return item !== id;
      });
      formik.setFieldValue("series", updatedSeries);
      formik.setFieldValue("players", updatedPlayer);
      formik.setFieldValue("teams", updatedTeams);
      setMergeData((prevHashtags) =>
        prevHashtags.filter((_, i) => i !== index)
      );
      setTagList((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    } else {
      setMergeData((prevHashtags) =>
        prevHashtags.filter((_, i) => i !== index)
      );
      setHashtags((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    }
  };

  const handleFileChange = (file) => {
    const selectedFile = file.currentTarget.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);
    setSelectedImage(imageUrl);
    formik.setFieldValue("files", selectedFile);
  };

  const handleOpen = async () => {
    if (id !== "add") {
      setUpdate(true);

      const response = await NotificationService.getNotification(
        statusRef.current,
        id
      );
      const result = response.data;
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("tag", result.tag);
      formik.setFieldValue("credit", result.credit);
      formik.setFieldValue("longdescription", result.longdescription);
      formik.setFieldValue("shortdescription", result.shortdescription);
      formik.setFieldValue("apptype", result.apptype);
      formik.setFieldValue("files", result.image);
      formik.setFieldValue("sendnotification", result.sendnotification);
      formik.setFieldValue("metatitle", result.metatitle);
      formik.setFieldValue("metakeywords", result.metakeywords);
      formik.setFieldValue("metadescription", result.metadescription);
      formik.setFieldValue(
        "series",
        result.tag.filter((item) => item.startsWith("S#"))
      );
      formik.setFieldValue(
        "players",
        result.tag.filter((item) => item.startsWith("P#"))
      );
      formik.setFieldValue(
        "teams",
        result.tag.filter((item) => item.startsWith("T#"))
      );
      setLoaderTag(true);
      const seriesHasTags = result.tag.filter((item) => item.startsWith("S#"));
      const playerHasTags = result.tag.filter((item) => item.startsWith("P#"));
      const teamsHasTags = result.tag.filter((item) => item.startsWith("T#"));

      const dataShow = SeriesOptions.filter((item) =>
        seriesHasTags.some(
          (prefix) => item.value === Number(prefix.split("S#")[1])
        )
      );
      const dataShowPLayer = PlayersOptions.filter((item) =>
        playerHasTags.some(
          (prefix) => item.value === Number(prefix.split("P#")[1])
        )
      );
      const dataShowTeam = TeamOptions.filter((item) =>
        teamsHasTags.some(
          (prefix) => item.value === Number(prefix.split("T#")[1])
        )
      );
      const updatedDataShow = dataShow.map((item) => ({
        ...item,
        update: true,
        series: "S",
      }));
      const updatedDataShowPLayer = dataShowPLayer.map((item) => ({
        ...item,
        update: true,
        series: "P",
      }));
      const updatedDataShowTeam = dataShowTeam.map((item) => ({
        ...item,
        update: true,
        series: "T",
      }));
      setMergeData([
        ...updatedDataShow,
        ...updatedDataShowPLayer,
        ...updatedDataShowTeam,
      ]);

      setLoaderTag(false);
      const filteredTags = result.tag.filter(
        (item) =>
          !item.startsWith("P#") &&
          !item.startsWith("T#") &&
          !item.startsWith("S#")
      );
      const withHashTags = result.tag.filter(
        (item) =>
          item.startsWith("P#") ||
          item.startsWith("T#") ||
          item.startsWith("S#")
      );
      const macthedDataSeries = Object.values(series).filter((item) =>
        seriesHasTags.map((data) => data.replace(/^S#/, "")).includes(item.id)
      );
      const macthedDataPlayer = Object.values(player).filter((item) =>
        playerHasTags.map((data) => data.replace(/^P#/, "")).includes(item.id)
      );
      const macthedDataTeams = Object.values(teams).filter((item) =>
        teamsHasTags.map((data) => data.replace(/^T#/, "")).includes(item.id)
      );
      const newData = macthedDataSeries.map((item) => item.n);
      const newDataPlayer = macthedDataPlayer.map((item) => item.n);
      const newDataTeams = macthedDataTeams.map((item) => item.n);
      setSeriesMap(newData);
      setPlayerMap(newDataPlayer);
      setTeamsMap(newDataTeams);
      setTagList(filteredTags);
      // setAllTags(withHashTags)
      const datalist = filteredTags.map((item) => item);
      setHashtags((prevHashtags) => [...prevHashtags, datalist.join("\n")]);
      // setChanges(result.apptype);
    }
  };

  useEffect(() => {
    handleOpen();
  }, [id]);

  useEffect(() => {
    if (!update) {
      const data = [...playerMap, ...seriesMap, ...teamsMap];
      setMergeDataAdd(data);
    } else if (update) {
      const data = [...playerMapUpdate, ...seriesMapUpdate, ...teamsMapUpdate];
      const uniqueData = Array.from(
        new Set(data.map((item) => item.value))
      ).map((value) => {
        return data.find((item) => item.value === value);
      });

      setMergeData((prev) => [...prev, ...uniqueData]);
    }
  }, [formik.values]);

  const filteredOptions = SeriesOptions.filter((option) =>
    option.label?.toLowerCase().includes(search.toLowerCase())
  );

  const teamOptions = TeamOptions.filter((option) =>
    option.label?.toLowerCase().includes(searchTeam.toLowerCase())
  );
  const playerOptions = PlayersOptions.filter((option) =>
    option.label?.toLowerCase().includes(searchPlayer.toLowerCase())
  );

  const loadMore = () => {
    setVisibleOptions((prev) => prev + 10);
  };
  const loadMoreTeam = () => {
    setVisibleOptionsTeam((prev) => prev + 10);
  };
  const loadMorePlayer = () => {
    setVisibleOptionsPlayer((prev) => prev + 10);
  };

  const changePLayers = (value, data) => {
    const removedValue =
      data.removedValue !== undefined && data.removedValue.label;
    setRemovedValue(removedValue);
    const updatedValues = value.map((option) => ({
      ...option,
      series: "P",
    }));
    const selectedValues = value.map((option) => option.value);
    setPlayerMap(value);
    setPlayerMapUpdate(updatedValues);
    formik.setFieldValue("players", selectedValues);
  };

  const changeSeries = (value, data) => {
    const removedValue =
      data.removedValue !== undefined && data.removedValue.label;
    setRemovedValue(removedValue);
    const updatedValues = value.map((option) => ({
      ...option,
      series: "S",
    }));
    const selectedValues = value.map((option) => option.value);
    setSeriesMap(value);
    setSeriesMapUpdate(updatedValues);
    formik.setFieldValue("series", selectedValues);
  };

  const changeTeams = (value, data) => {
    const removedValue =
      data.removedValue !== undefined && data.removedValue.label;
    setRemovedValue(removedValue);
    const updatedValues = value.map((option) => ({
      ...option,
      series: "T",
    }));
    const selectedValues = value.map((option) => option.value);
    setTeamsMap(value);
    setTeamsMapUpdate(updatedValues);
    formik.setFieldValue("teams", selectedValues);
  };

  function truncateString(str, num) {
    if (str && str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  return (
    <>
      <div className="page-header2"></div>
      <div
        className="me-auto d-flex justify-content-start"
        style={{ fontWeight: 600, fontSize: "20px", marginBottom: "10px" }}
      >
        {update ? "UPDATE NEWS" : "ADD NEWS"}
      </div>
      <Row>
        <Card className="custom-card">
          <form onSubmit={formik.handleSubmit}>
            <Card.Body>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div>
                    <div className="border-bottom mb-3">
                      <FormGroup className="form-group">
                        <Row className=" row-sm">
                          <div
                            className="me-auto d-flex justify-content-start"
                            style={{ fontWeight: 500, fontSize: "16px" }}
                          ></div>
                        </Row>
                      </FormGroup>
                    </div>
                    <div>
                      <div className="row">
                        <div className={` ${!isTablet && "col-6"}`}>
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                                fontWeight: "500",
                              }}
                            >
                              Short Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="shortdescription"
                              placeholder="Short Description"
                              value={formik.values.shortdescription}
                              onChange={formik.handleChange}
                              style={{ minHeight: "100px" }}
                            />
                            {formik.errors.shortdescription &&
                            formik.touched.shortdescription ? (
                              <p className="text-start error">
                                {formik.errors.shortdescription}
                              </p>
                            ) : null}
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                                fontWeight: "500",
                              }}
                            >
                              Long Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="longdescription"
                              placeholder="Long Description"
                              value={formik.values.longdescription}
                              onChange={formik.handleChange}
                              style={{ minHeight: "300px" }}
                            />
                            {formik.errors.longdescription &&
                            formik.touched.longdescription ? (
                              <p className="text-start error">
                                {" "}
                                {formik.errors.longdescription}
                              </p>
                            ) : null}

                            <Box sx={{ display: "flex", gap: 2 }}>
                              <div
                                style={{
                                  display: "block",
                                  width: "100%",
                                }}
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Meta Title
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="metatitle"
                                  placeholder="Meta Title"
                                  value={formik.values.metatitle}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.metatitle &&
                                formik.touched.metatitle ? (
                                  <p className="text-start error">
                                    {formik.errors.metatitle}
                                  </p>
                                ) : null}
                              </div>

                              <div
                                style={{
                                  display: "block",
                                  width: "100%",
                                }}
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Meta Description
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="metadescription"
                                  placeholder="Meta Description"
                                  value={formik.values.metadescription}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.metadescription &&
                                formik.touched.metadescription ? (
                                  <p className="text-start error">
                                    {formik.errors.metadescription}
                                  </p>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "block",
                                  width: "100%",
                                }}
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Meta Keywords
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="metakeywords"
                                  placeholder="Meta Keywords"
                                  value={formik.values.metakeywords}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.metakeywords &&
                                formik.touched.metakeywords ? (
                                  <p className="text-start error">
                                    {formik.errors.metakeywords}
                                  </p>
                                ) : null}
                              </div>
                            </Box>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box sx={{ display: "flex" }}>
                                <div
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  <Form.Label
                                    style={{
                                      textAlign: "start",
                                      color: "#000",
                                      marginTop: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Credit
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="credit"
                                    placeholder="Credit"
                                    value={formik.values.credit}
                                    onChange={formik.handleChange}
                                  />
                                  {formik.errors.credit &&
                                  formik.touched.credit ? (
                                    <p className="text-start error">
                                      {formik.errors.credit}
                                    </p>
                                  ) : null}
                                </div>
                              </Box>
                              {/* {!update && ( */}
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "37px",
                                  marginTop: "34px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="sendnotification"
                                  checked={formik.values.sendnotification}
                                  onChange={(e) => handleNotification(e)}
                                />
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    fontWeight: "500",
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Send Notification
                                </Form.Label>
                              </div>
                              {/* )} */}
                            </div>
                          </Form.Group>
                        </div>
                        <div
                          className={` ${!isTablet && "col-6"} pos - relative`}
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                              fontWeight: "500",
                            }}
                          >
                            File
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="files"
                            onChange={(e) => handleFileChange(e)}
                            style={{ maxWidth: "250px" }}
                          />
                          {formik.errors.files && formik.touched.files ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.files}
                            </p>
                          ) : null}
                          {update ? (
                            <img
                              src={
                                selectedImage.length !== 0
                                  ? selectedImage
                                  : formik.values.files
                              }
                              style={{ marginTop: "10px", height: "300px" }}
                              alt=""
                            />
                          ) : (
                            selectedImage.length !== 0 && (
                              <img
                                src={selectedImage}
                                style={{
                                  marginTop: "10px",
                                  height: "300px",
                                  width: "450px",
                                }}
                                alt=""
                              />
                            )
                          )}
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              fontWeight: "500",
                              marginTop: "15px",
                            }}
                          >
                            Hashtag
                          </Form.Label>
                          <div className={`${!isTabletMore && "d-flex"}`}>
                            <div
                              style={{
                                marginRight: "15px",
                                maxWidth: "250px",
                                minWidth: "250px",
                              }}
                            >
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Series
                              </Form.Label>
                              <Multiselect
                                classNamePrefix="Select2 z-index-10"
                                options={filteredOptions.slice(
                                  0,
                                  visibleOptions
                                )}
                                name="series"
                                onChange={(e, data) => changeSeries(e, data)}
                                isSearchable
                                isMulti
                                onMenuScrollToBottom={loadMore}
                                menuIsOpen={openCity}
                                onMenuOpen={handleOpenCity}
                                onMenuClose={handleCloseCity}
                                styles={{
                                  indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                }}
                                // value={flattenedArraySeries.filter((option) => option.value === formik.values.series.map((item) => item.split("S#")))}
                                // value={flattenedArraySeries.filter((option) => option.value)}
                                onInputChange={(inputValue) =>
                                  setSearch(inputValue)
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginRight: "15px",
                                minWidth: "250px",
                                maxWidth: "250px",
                              }}
                            >
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Teams
                              </Form.Label>
                              <Multiselect
                                classNamePrefix="Select2 z-index-10"
                                options={teamOptions.slice(
                                  0,
                                  visibleOptionsTeam
                                )}
                                name="teams"
                                onChange={(e, data) => changeTeams(e, data)}
                                isSearchable
                                isMulti
                                onMenuScrollToBottom={loadMoreTeam}
                                // menuIsOpen={openCity2}
                                // onMenuOpen={handleOpenCity2}
                                // onMenuClose={handleCloseCity2}
                                // value={ColorStatus.find(
                                //   (option) => option.value === formik.values.colorCodeId
                                // )}
                                styles={{
                                  indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                }}
                                onInputChange={(inputValue) =>
                                  setSearchTeam(inputValue)
                                }
                              />
                            </div>
                            {!isTabletMoreLaptop && (
                              <div
                                style={{ minWidth: "250px", maxWidth: "250px" }}
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                  }}
                                >
                                  Players
                                </Form.Label>
                                <Multiselect
                                  classNamePrefix="Select2 z-index-10"
                                  name="players"
                                  onChange={(e, data) => changePLayers(e, data)}
                                  options={playerOptions.slice(
                                    0,
                                    visibleOptionsPlayer
                                  )}
                                  onMenuScrollToBottom={loadMorePlayer}
                                  isSearchable
                                  isMulti
                                  styles={{
                                    indicatorsContainer: (provided) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                  }}
                                  // menuIsOpen={openCity2}
                                  // onMenuOpen={handleOpenCity2}
                                  // onMenuClose={handleCloseCity2}
                                  // value={!update && PlayersOptions.filter(item => formik.values.players.some(prefix => item.value === prefix.split("P#")[1]))
                                  //   .map(matchedItem => matchedItem.value)}
                                  onInputChange={(inputValue) =>
                                    setSearchPlayer(inputValue)
                                  }
                                />
                              </div>
                            )}
                          </div>

                          {isTabletMoreLaptop && (
                            <div
                              style={{
                                minWidth: "250px",
                                maxWidth: "250px",
                              }}
                            >
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Players
                              </Form.Label>
                              <Multiselect
                                classNamePrefix="Select2 z-index-10"
                                name="players"
                                onChange={(e, data) => changePLayers(e, data)}
                                options={playerOptions.slice(
                                  0,
                                  visibleOptionsPlayer
                                )}
                                onMenuScrollToBottom={loadMorePlayer}
                                isSearchable
                                isMulti
                                styles={{
                                  indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                }}
                                // menuIsOpen={openCity2}
                                // onMenuOpen={handleOpenCity2}
                                // onMenuClose={handleCloseCity2}
                                // value={PlayersOptions.filter(item => formik.values.players.some(prefix => item.value === prefix.split("P#")[1]))
                                //   .map(matchedItem => matchedItem.value)}
                                onInputChange={(inputValue) =>
                                  setSearchPlayer(inputValue)
                                }
                              />
                            </div>
                          )}

                          {loaderTag ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress
                                size={20}
                                style={{
                                  textAlign: "center",
                                  marginTop: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                  color: "#000",
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className="hashtag-input-container-main"
                              style={{
                                marginTop: "15px",
                              }}
                            >
                              <div
                                className={`${
                                  (mergeDataAdd.length > 0 ||
                                    mergeData.length > 0) &&
                                  "hashtag-input-container"
                                }`}
                              >
                                {update
                                  ? mergeData &&
                                    mergeData
                                      .filter(
                                        (hashtag, index, self) =>
                                          index ===
                                          self.findIndex(
                                            (h) =>
                                              h &&
                                              hashtag &&
                                              h.label === hashtag.label
                                          )
                                      )
                                      .map((hashtag, index) => (
                                        <div
                                          className={`${
                                            hashtag ? "hashtag-box m-1" : ""
                                          } `}
                                          style={{
                                            cursor: hashtag.update
                                              ? "pointer"
                                              : "default",
                                          }}
                                        >
                                          <span
                                            key={index}
                                            title={hashtag.label}
                                            className="hashtag"
                                            style={{
                                              cursor: hashtag.update
                                                ? "pointer"
                                                : "default",
                                            }}
                                            onClick={() =>
                                              hashtag.update &&
                                              handleHashtagRemove(
                                                index,
                                                hashtag.value
                                              )
                                            }
                                          >
                                            {truncateString(hashtag.label, 25)}
                                            {hashtag.update && (
                                              <span className="hashtag-remove ms-2">
                                                &times;
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ))
                                  : mergeDataAdd.map((hashtag, index) => (
                                      <div
                                        className={`${
                                          hashtag ? "hashtag-box" : ""
                                        } `}
                                      >
                                        <span
                                          key={index}
                                          title={hashtag.label}
                                          className="hashtag"
                                        >
                                          {" "}
                                          {hashtag.label}
                                        </span>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          )}
                          {formik.errors.tag && formik.touched.tag ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.tag}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  style={{ marginTop: "15px", marginRight: "15px" }}
                  onClick={() => navigate(-1)}
                  type="button"
                  className="btn btn-danger"
                >
                  CANCEL
                </button>
                <Button style={{ marginTop: "15px" }} type="submit">
                  {loader ? (
                    <CircularProgress
                      size={20}
                      style={{ textAlign: "center", color: "#fff" }}
                    />
                  ) : update ? (
                    "UPDATE NEWS"
                  ) : (
                    "ADD NEWS"
                  )}
                </Button>
              </div>
            </Card.Footer>
          </form>
        </Card>
      </Row>
    </>
  );
};
